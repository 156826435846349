import React, {useState} from 'react';
import styled from 'styled-components';
import {Helmet} from 'react-helmet-async';
import {Link, NavLink} from 'react-router-dom';
import {FaBars} from 'react-icons/fa';

import useViewer from "../../lib/use-viewer";
import {BackgroundWorker} from "./BackgroundWorker";
import {version} from "../../config";
import EditAccount from "../AccountMenu/EditAccount";

const AuthenticationButtons = () => {
    const {loading, viewer} = useViewer();

    const buttons = [
    ];

    // const admin = ((viewer && viewer.firstName) || '').toLowerCase();

    const linkAttributes:any = {};

    if (!window.localStorage.getItem('noTabs'))
        linkAttributes['target'] = '_blank';

    const [editingAccount, setEditingAccount] = useState(false);
    const editProfile = (e:any) => {
        setEditingAccount(true);
        e.preventDefault();
    }

    if (viewer) {
        buttons.push(<NavLink key="logout" to="/logout">Log Out {viewer.firstName}</NavLink>);
        buttons.push(<a key="edit" href="/profile" onClick={editProfile}>Profile</a>);
    }
    else if (!loading) {
        // buttons.push(<NavLink key="login" to="/login">Log In</NavLink>);
    }

    return (
        <>
            {buttons}
            {viewer && <BackgroundWorker/>}
            {editingAccount && (
                <EditAccount accountId={viewer?.id} close={() => setEditingAccount(false)}/>
            )}
        </>
    );
};

const Main = styled.div`
  position: relative;
`;

const Logo = styled(Link).attrs({
    children:'BTA Dashboard'
})`
  color: #fff;
  text-decoration: none;
  text-align: right;
  font-size: 24px;
  font-weight: bold;
`;

const Toggle = styled.div`
  text-align: right;
  font-size: 10vw;
  opacity: .85;
`;

const Nav = styled.nav`
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    font-size: 12pt;
    
    ${Toggle} {
      display: none;
    }

    a {
        margin: .25em;
        padding: .5em .5em;
        text-decoration: none !important;
        white-space: nowrap;
        color: #fff;
        outline: none;
        
        &:hover, &.active {
            color: ${({theme:{lightGreen}}) => lightGreen};
        }
        
        transition: all ease 0.7s;
    }

    @media (max-width:900px) {
        font-size: 2vw;
    }

    @media (max-width:480px) {
        font-size: 7vw;
        position: absolute;
        right: 0;
        top: 0;
        flex-direction: column;
        
        ${Toggle} {
          display: block;
        }
        
        &  a {
          display: none;
          margin: 0;
          padding: .5em 1em;
          border: 1px solid rgba(255,255,255,.25);
          border-bottom: none;
          background: ${({theme:{accent}}) => accent};
        }
        
        &.show a {
          display: block;
        }
        
        & a:last-child {
          border-bottom: 1px solid rgba(255,255,255,.25);
        }
    }
`;


const Menu = () => {
    const [show, setShow] = useState(false);

    return (
        <Nav className={show ? 'show' : ''}>
            <Toggle>
                <FaBars onClick={() => setShow(!show)}/>
            </Toggle>
            <AuthenticationButtons/>
        </Nav>
    );
};

const Header = styled(({className,title='RBN'}) => (
    <header className={className}>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <Main>
            <Logo to="/dashboard"/>
            <Menu/>
        </Main>
    </header>
))`
    color: #fff;
    background: ${({background}) => /local|dev/.test(version) ? '#060' : background};
    position: ${({background}) => background === 'transparent' || /rgba\(/.test(background) ? 'absolute' : 'relative'};
    width: 100%;
    z-index: 16383;
    padding: 1rem 0;
    text-shadow: 1px 1px 2px ${({theme:{accent}}) => accent};
    
    @media (max-width: 480px) {
      padding: .5rem;
    }
    
    & > div {
        max-width: ${({theme:{width}}) => width};
        margin: 0 auto;
        display: flex;
        align-items: center;
    }
`;

export default Header;