import loginAsMutationDocument from "../gql/LoginAs";
import {apolloClient, version} from "../config";

let LOCAL_SERVER = '';

if (version === 'local' || version === 'dev') {
    const local = `${document.location.protocol}//${document.location.hostname}:3000`;

    fetch(local, {mode: 'no-cors'})
        .then(() => {
            LOCAL_SERVER = local;
            console.log(`local ui server detected`);
        })
        .catch(() => {
            console.log('local ui server not detected');
            // console.error(e);
        });
}

export default function loginAs(account:any) {
    return new Promise((resolve, reject) => {
        const name = (account.name || `${account.firstName} ${account.lastName}` || '').trim();

        if (window.confirm(`Login as ${name}?`)) {
            resolve(apolloClient.mutate({
                mutation: loginAsMutationDocument,
                variables: {
                    input: {
                        accountId: account.accountId || account.id
                    }
                }
            }).then(async ({data}) => {
                if (!(data && data.loginAs && data.loginAs.link))
                    return;

                const {loginAs: {link: {id}}} = data;

                let baseUri;

                switch (version) {
                    // case 'local':
                    // case 'dev':
                    //     baseUri = 'https://dev.joinrbn.com';
                    //     break;
                    case 'staging':
                        baseUri = 'https://staging.joinrbn.com';
                        break;
                    case 'production':
                        baseUri = 'https://joinrbn.com';
                        break;
                    default:
                        baseUri = LOCAL_SERVER || 'https://dev.joinrbn.com';
                        break;
                }

                const url = `${baseUri}/login/${id}`;

                window.open(url, 'login-as');
            }));
        }
        else {
            reject();
        }
    });
}
