import React from 'react'
import {Routes, Route} from 'react-router-dom';

import {
        Consumers,
        Login,
        Logout,
} from './pages';

function AppRoutes() {
        return (
            <Routes>
                    <Route path="/" element={<Login/>}/>
                    <Route path="/logout" element={<Logout/>}/>
                    <Route path="/dashboard/*" element={<Consumers/>}/>
            </Routes>
        );
}

export default AppRoutes;