import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Link} from "react-router-dom";

import {getJWT, useClearStorage, useLogin, useLoginRedirect, useResetPassword} from '../../lib/authentication';

import Page from '../../components/Page'
import {Form, Input, Buttons, Button} from '../../components/form';
import Error from '../../components/Error';

import {Full} from '../../components/form/Box';

const Wrapper = styled.div`
  max-width: 500px;
  position: relative;
  margin: 0 auto;
  
  ${Buttons} + a {
    display: block;
    color: #fff;
    margin-top: 1rem;
    text-decoration: none;
    font-size: 90%;
    text-align: right;
    min-width: 400px;
  }
  
  @media (max-width:480px) {
    width: 100vw;
  }
`;

const LoginForm = ({showForgotPassword}:{showForgotPassword:(e:any)=>void}) => {
    const {login, fields:{email, password}, updateField, error} = useLogin();
    const loginRedirect = useLoginRedirect();

    if (getJWT()) {
        loginRedirect();
    }

    return (
        <Form onSubmit={login} box={true}>
            <Full heading="Login">
                <Input name="email" value={email} onChange={updateField} label="Email Address" type="email" required={true} autoFocus autoComplete="username"/>
                <Input name="password" value={password} onChange={updateField} label="Password" type="password" required={true} autoComplete="current-password"/>
                <Error error={error}/>
                <Buttons>
                    <Button variation="light-blue">Login</Button>
                </Buttons>
                <Link to="/" onClick={showForgotPassword}>forgot password?</Link>
            </Full>
        </Form>
    );
};

const ForgotPasswordForm = ({cancel}:{cancel:(e:any)=>void}) => {
    const {resetPassword, fields:{email}, updateField, error} = useResetPassword();
    const [emailSent, setEmailSent] = useState(false);

    const submitHandler = (e:any) => {
        resetPassword(e)
            .then(() => {
                setEmailSent(true);
            });
    };

    return (
        <Form onSubmit={submitHandler} box={true}>
            {emailSent ? (
                <Full heading="Email Sent">
                    <p>Please check your email for instructions.</p>
                </Full>
            ) : (
                <Full heading="Forgot Password">
                    <Input name="email" value={email} onChange={updateField} label="Email Address" type="email" required={true} autoFocus/>
                    <Error error={error}/>
                    <Buttons>
                        <Button variation="light-blue">Reset Password</Button>
                        <Button variation="light-blue" onClick={cancel}>Cancel</Button>
                    </Buttons>
                </Full>
            )}
        </Form>
    );
};

const Login = () => {
    const [forgotPassword, setForgotPassword] = useState(false);
    const clearStorage  = useClearStorage();

    useEffect(() => {
        clearStorage().then();
    });

    const showForgotPassword = (e:any) => {
        e && e.preventDefault();
        setForgotPassword(true);
    };

    const hideForgotPassword = (e:any) => {
        e && e.preventDefault();
        setForgotPassword(false);
    };

    return (
        <Page title='Admin Log In'>
            <Wrapper>
                {forgotPassword ? <ForgotPasswordForm cancel={hideForgotPassword}/> : <LoginForm showForgotPassword={showForgotPassword}/>}
            </Wrapper>
        </Page>
    );
};

export default Login;
