function getVersion() {
    let version = 'local';

    switch (document.location.hostname) {
        case 'dev.admin.joinrbn.com':
            version = 'dev';
            break;
        case 'staging.admin.joinrbn.com':
            version = 'staging';
            break;
        case 'admin.joinrbn.com':
        case 'bta.rbnrewards.com':
            version = 'production';
            break;
    }

    return version;
}

export const version = getVersion();

if (!['production'].includes(version))
    console.log(`%c stage: ${version}`, 'font-size: 24px; font-weight: bold; color: #090; margin: 1em 0;');

if (['local', 'dev'].includes(version))
    document.getElementById('favicon')?.setAttribute('href', '/favicon-dev.png');