import useViewer from "../../lib/use-viewer";
import {useLazyQuery, useMutation} from "@apollo/client";
import RemoveAccountMutationDocument from "../../gql/RemoveAccount";
import {providerByIdQueryDocument} from '../../gql/Provider';
import React, {useCallback, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {FaChevronDown, FaChevronUp} from "react-icons/fa";
import {useUpdateProvider} from '../../lib/use-provider';
import {api, JWT_KEY} from "../../config";
import EditAccount from "./EditAccount";

interface Data {
    nodeId: string,
    firstName: string,
    lastName: string,
    [key: string]: any,
}

interface AccountMenuProps {
    className?: string,
    data: Data
}

interface MenuOptionsProps {
    data: any,
    open: boolean,
    closeMenu: (e?:any) => void,
}

const MenuOptions = ({data, open, closeMenu}:MenuOptionsProps) => {
    const {viewer} = useViewer();
    const [removeAccount] = useMutation(RemoveAccountMutationDocument);
    const updateProvider = useUpdateProvider();
    const [editingAccount, setEditingAccount] = useState(false);
    const [getProviderById] = useLazyQuery(providerByIdQueryDocument);

    useEffect(() => {
        if (open && !editingAccount) {
            document.addEventListener('click', closeMenu);

            return () => document.removeEventListener('click', closeMenu);
        }
    }, [open, editingAccount, closeMenu]);

    const admin = ((viewer && viewer.firstName) || '').toLowerCase();

    const confirmRemoveAccount = !admin.match(/aaron|mia/) ? null : async () => {
        if (window.confirm(`Are you sure you want to remove ${data.firstName} ${data.lastName}?`)) {
            await removeAccount({
                variables: {
                    input: {
                        aid: data.accountId
                    }
                }
            }).then();
        }
    };

    // const {suppressMessages} = provider;

    // const toggleMessageSuppression = () => {
    //     updateAccount({
    //         variables: {
    //             input: {
    //                 nodeId: provider.accountNodeId,
    //                 patch:{
    //                     suppressMessages: !suppressMessages
    //                 }
    //             }
    //         }
    //     }).then(refetch);
    // }

    // const toggleProviderApproved = () => {
    //     const patch:ProviderPatch = {
    //         approved: !data.approved
    //     };
    //
    //     if (!patch.approved)
    //         patch.public = false;
    //
    //     updateProvider(data.nodeId, patch).then(refetch);
    // }

    const statusLi = (status:string, prompt:string) => {
        const isSet = data.status?.status === status;

        const handler = () => {
            const note = window.prompt(prompt);

            if (!note)
                return;

            const history = (data.status?.history || []).slice();

            if (data.status) {
                const {history: _, ...notHistory} = data.status;

                history.push(notHistory);
            }

            const newStatus:any = {
                admin,
                note,
                updated: new Date(),
                history
            };

            if (!isSet)
                newStatus.status = status;

            updateProvider(data.nodeId, {
                status: newStatus
            }).then();
        }

        return <li onClick={handler}>{isSet ? 'Unset' : 'Set'} {status}</li>;
    }

    // const toggleProviderPublic = () => {
    //     if (data.public || window.confirm(`Are you sure you want make ${data.firstName} ${data.lastName} public?`)) {
    //         updateProvider(data.nodeId, {
    //             public: !data.public
    //         }).then(refetch);
    //     }
    // }

    const provider = data.provider;
    const consumer = !!(data.sides);

    const sendAPA = (force?: any) => {
        const params:any = {
            accountId: data.accountId
        };

        if (typeof force === 'boolean' && force)
            params.force = true;

        fetch(`${api.endpoint}/send-apa`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${localStorage.getItem(JWT_KEY)}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(response => response.json())
            .then(json => {
                if (typeof force !== 'boolean' && json.error === 'Already sent APA') {
                    if (window.confirm(`APA has already been sent. Are you sure you want to resend?`)) {
                        sendAPA(true);
                    }
                }
                else {
                    window.alert(json.message || json.error);

                    if (json.message) {
                        getProviderById({variables: {id: provider.id}}).then();
                    }
                }
            });
    }

    const apaSigned = provider && data.signed;

    const editAccount = () => {
        setEditingAccount(true);
        document.removeEventListener('click', closeMenu);
        // closeMenu();
    }

    return (
        <>
            {open && !editingAccount && (
                <ul>
                    {consumer && admin.match(/aaron|mia|justin|kara|kristina/) && <li><a href={`/consumers/points/${data.id}`} style={{color: 'inherit', textDecoration: 'none'}} target={window.localStorage.getItem('noTabs') ? undefined : '_blank'} rel="noreferrer">Points</a></li>}
                    {confirmRemoveAccount && <li onClick={confirmRemoveAccount}>Remove {data.firstName} {data.lastName}</li>}
                    <li onClick={editAccount}>Edit Account</li>
                    {provider && <li onClick={sendAPA}>Send APA</li>}
                    {apaSigned && statusLi('Approved', 'Please add a note:')}
                    {apaSigned && statusLi('Bench', 'Please add a note:')}
                    {provider && statusLi('Rejected', 'Please add a note:')}
                </ul>
            )}
            {editingAccount && (
                <EditAccount accountId={data.accountId} close={closeMenu}/>
            )}
        </>
    )
}

const AccountMenu = styled(({className, data:provider}:AccountMenuProps) => {
    const [open, setOpen] = useState(false);

    const openTime = useRef(0);

    const toggleMenu = useCallback(() => {
        setOpen(open => !open);

        openTime.current = Date.now();
    }, [setOpen]);
    const closeMenu = () => {
        if (Date.now() - openTime.current < 100)
            return;

        setOpen(false);
    }

    return (
        <div className={className}>
            <span onClick={toggleMenu}>{open ? <FaChevronUp/> : <FaChevronDown/>}</span>
            {open && <MenuOptions data={provider} open={open} closeMenu={closeMenu}/>}
        </div>
    )
})`
  position: relative;
  
  & > :first-child svg {
    font-size: 12px;
    cursor: pointer;
    color: ${({theme:{accent}}) => accent};
  }
  
  ul {
    z-index: 2;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 100%;
    background: ${({theme:{accent}}) => accent};
    color: #fff;
  }
  
  li {
    padding: .25rem .5rem;
    cursor: pointer;
    white-space: nowrap;
    border-bottom: 1px solid rgba(255,255,255,.5);
  }
  li:last-child {
    border-bottom: none;
  }
`;

export default AccountMenu;